import React from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import { Fv } from "components/fv"

const CleaningPage = () => {
  return (
    <Layout>
      <div className="cleaning design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/mv-img.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/sp-mv-img.jpg"
          fvTitle="Cleaning"
          color="black"
        />
        <FadeIn.Up>
          <section className="lead-area">
            {/* PC */}
            <h2 className="pc title tx-center">
              美しいシルエットを楽しみたい衣類に
              <br />
              バルコのドライクリーニング
            </h2>
            {/* SP */}
            <h2 className="sp title tx-center">
              美しいシルエットを
              <br />
              楽しみたい衣類に
              <br />
              バルコのドライクリーニング
            </h2>
            {/* PC */}
            <p className="pc lead tx-center fw-b">
              ドライクリーニングは専用の溶剤を使用し、衣類の表面、主に油性汚れの除去を行う、クリーニング方法のひとつです。<br />
              水洗いに適さない衣類の素材や色、シルエットを保つのに最適です。<br />
              汗などの水溶性の汚れには水溶性の溶剤を使用するオプション、ダブルクリーニングがおすすめです。<br />
            </p>
            {/* SP */}
            <p className="sp lead fw-b">
              ドライクリーニングは専用の溶剤を使用し、衣類の表面、主に油性汚れの除去を行う、クリーニング方法のひとつです。
              水洗いに適さない衣類の素材や色、シルエットを保つのに最適です。
              汗などの水溶性の汚れには水溶性の溶剤を使用するオプション、ダブルクリーニングがおすすめです。
            </p>
            <div className="title-area-style tx-center">
              <h2 className="title e">Price</h2>
              <p className="sub-title fw-b">価格</p>
            </div>
          </section>
        </FadeIn.Up>

        <FadeIn.Up>
          <section className="main-contents">
            <div className="price-list tx-center">
              <div className="price-list-inner">
                <img
                  className="lazy pc"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cleaningmenu_2402.png"
                  width={740}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy sp"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cleaningmenu_2402.png"
                  width="100%"
                  alt=""
                  loading="lazy"
                />
                <p className="note tx-left">※表は代々木上原店の価格です。</p>
              </div>
            </div>
            <div className="option-list">
              <div className="option-list-inner">
                <h2 className="title fw-b tx-center">オプション</h2>
                <div className="option-list-contents">
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">ワイシャツ</span>
                        <br />
                        <span className="fw-b">ホワイトニング</span>
                        <br />
                        <span>+¥880</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p style={{ margin: "30px 0" }}>
                        付着した皮脂汚れに集中的な漂白ケアをし、すっきりと美しく仕上げます。
                      </p>
                    </div>
                  </div>
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">しみ抜き</span>
                        <br />
                        <span className="">¥330〜</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p className="">
                        熟練の職人が、さまざまな汚れによるしみ汚れも、衣類の素材や柄などに合わせ最適な方法で慎重にとりのぞきます。
                        <br />
                        <span className="">
                          ※時間が経過してしまったものなど一部対応できないしみ汚れがございます。
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">ダブルクリーニング</span>
                        <br />
                        <span>価格に対し×1.3倍</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p>
                        ドライクリーニングでは落とし切れない汗や皮脂汚れを、専用の水溶性洗剤を使用して衣類を２度洗い、ダブル洗浄で衣類の表面と内側の汚れをさっぱり洗い上げます。
                      </p>
                    </div>
                  </div>
                  <div className="option-list-contents-item">
                    <div className="option-list-contents-left">
                      <p>
                        <span className="fw-b">ハイエンド</span>
                        <br />
                        <span>価格に対し×1.5倍</span>
                      </p>
                    </div>
                    <div className="option-list-contents-right">
                      <p>
                        通常のドライクリーニングより、回転を弱めることで衣類への負担を軽減します。専用のリンス剤を加え、衣類の表面に潤いの膜をコーティングさせることで肌触りの良い仕上がりになります。高級獣毛のアイテムやシルク製品、装飾がついている衣類におすすめです。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="annotation">
              ※価格はすべて税込です。
              <br />
              ※表は代々木上原店の価格です。店舗により、価格が異なります。詳しくは店頭にてご確認ください。
              <br />
              ※カシミヤ、アルパカ、アンゴラ、モヘアなど、高級獣毛が30%以上使用されている衣類は、ハイエンドでのお預かりとなります。
              <br />
              ※素材や損傷が激しいものなど、一部お受けできないものがございます。
              <br />
            </p>
            <div className="title-area-style tx-center">
              <h2 className="title e">Special Dry Cleaning</h2>
              <p className="sub-title fw-b">特殊クリーニング</p>
            </div>
            {/* PC */}
            <p className="pc special-dry-cleaning-lead tx-center fw-b">
              帽子、革製の靴、着物、バッグ、毛皮の他、水洗いができない布団、絨毯、ムートンなどの
              <br />
              クリーニングもお受けしております。詳しくはスタッフにご相談ください。
            </p>
            {/* SP-*/}
            <p className="sp special-dry-cleaning-lead fw-b">
              帽子、革製の靴、着物、バッグ、毛皮の他、水洗いができない布団、絨毯、ムートンなどのクリーニングもお受けしております。
              <br />
              詳しくはスタッフにご相談ください。
            </p>
            {/* PC */}
            <div className="pc">
              <div className="sample-photo">
                <img
                  className="lazy pc"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item01.jpg"
                  width={236}
                  height={300}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy pc"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item02.jpg"
                  width={236}
                  height={300}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy pc"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item03.jpg"
                  width={236}
                  height={300}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            {/* SP */}
            <div className="sp">
              <div className="sample-photo">
                <img
                  className="lazy sp"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item01.jpg"
                  width={105}
                  height={133}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy sp"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item02.jpg"
                  width={105}
                  height={133}
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy sp"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-item03.jpg"
                  width={105}
                  height={133}
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div className="page-mv-wrap tx-center">
              <div className="page-mv">
                <img
                  className="lazy pc"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-yoyogi-pc.jpg"
                  height="auto"
                  alt=""
                  loading="lazy"
                />
                <img
                  className="lazy sp"
                  src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/cl-yoyogi-sp.jpg"
                  height="auto"
                  alt=""
                  loading="lazy"
                />
              </div>
              <div className="tx-center mv-page-title">
                <h2 className="title e">Specialized Care</h2>
                <p className="sub-title fw-b">店舗限定メニュー</p>
              </div>
            </div>
            <div className="specialized-menu-contents">
              <div className="specialized-menu-contents-inner">
                {/* PC */}
                <p className="pc lead tx-center fw-b">
                  お気に入りのアイテムを、長く最適な状態で着ていただけるように、バルコが提案する新しいお手入れです。
                  <br />
                  クリーニング技術で汚れを落とすだけではなく、思い入れのあるアイテムを
                  <br />
                  大切に長くお使いいただけるようにお手伝いをさせていただきます。
                  <br />
                  お気軽にスタッフにご相談ください。
                </p>
                {/* SP */}
                <p className="sp lead fw-b">
                  お気に入りのアイテムを、長く最適な状態で着ていただけるように、バルコが提案する新しいお手入れです。
                  クリーニング技術で汚れを落とすだけではなく、思い入れのあるアイテムを大切に長くお使いいただけるようにお手伝いをさせていただきます。
                  <br />
                  お気軽にスタッフにご相談ください。
                </p>
                <div className="specialized-menu-contents-link-area">
                  <Link to="/cleaning-sneaker" className="page-link">
                    <img
                      className="lazy pc"
                      src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/pc-specialized-item01.jpg"
                      width={460}
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                  <Link to="/cleaning-sneaker" className="page-link">
                    <img
                      className="lazy sp"
                      src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/sp-specialized-item01.jpg"
                      width="100%"
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                  <a href="/cleaning-denim" className="page-link">
                    <img
                      className="lazy pc"
                      src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/pc-specialized-item02.jpg"
                      width={460}
                      alt=""
                      loading="lazy"
                    />
                  </a>
                  <Link to="/cleaning-denim" className="page-link">
                    <img
                      className="lazy sp"
                      src="https://balukoweb.blob.core.windows.net/images/laundry-service/cleaning/sp-specialized-item02.jpg"
                      width="100%"
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="title-area-style tx-center">
              <h2 className="title e">Store</h2>
              <p className="sub-title fw-b">取扱い店舗</p>
            </div>
            <div className="store-contents">
              <div className="store-contents-inner">
                <ul className="store-contents-list">
                  <li className="store-contents-item">
                    <div className="store-contents-item-box">
                      <img
                        className="lazy pc"
                        srcSet="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width={413}
                        alt=""
                        loading="lazy"
                      />
                      <img
                        className="lazy sp"
                        srcSet="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width="100%"
                        alt=""
                        loading="lazy"
                      />
                      <div className="store-contents-item-box-detail">
                        <p className="store-name fw-b">代々木上原</p>
                        <p className="store-address">東京都渋谷区上原3-29-2</p>
                        <Link className="store-link" to="/baluko-uehara">
                          店舗詳細
                        </Link>
                      </div>
                    </div>
                  </li>

                  {/* 狛江店 */}
                  <li className="store-contents-item">
                    <div className="store-contents-item-box">
                      <img
                          className="lazy pc"
                          src="https://balukoappprod.blob.core.windows.net/images/shops/2024-01/1705548087_komae_1.jpg"
                          width={413}
                          alt=""
                          loading="lazy"
                      />
                      <img
                          className="lazy sp"
                          src="https://balukoappprod.blob.core.windows.net/images/shops/2024-01/1705548087_komae_1.jpg"
                          width="100%"
                          alt=""
                          loading="lazy"
                      />
                      <div className="store-contents-item-box-detail">
                        <p className="store-name fw-b">
                          狛江
                        </p>
                        <p className="store-address">
                          東京都狛江市和泉本町2-33-22
                        </p>
                        <Link className="store-link" to="/baluko-komae/">
                          店舗詳細
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </FadeIn.Up>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Cleaning - バルコのドライクリーニング"
    description="水洗いに適さない衣類の素材や色、シルエットを保つのに最適なBaluko Laundry Placeのドライクリーニングサービス。サービス内容と料金、取り扱い店舗情報を掲載しています。"
  />
)
export default CleaningPage
